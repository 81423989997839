<template>
  <div class="widget-container">
    <b-container>
      <PrivacyPolicyText />
      <div class="text-center mt-5">
        <BackHome />
      </div>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import PrivacyPolicyText from "@/components/PrivacyPolicyText.vue";
import BackHome from "@/components/BackHome.vue";

export default {
  name: "AboutView",
  components: {
    PrivacyPolicyText,
    BackHome,
  },
};
</script>
