<template>
  <div>
    <b-row align-v="center" class="mb-3 d-flex justify-content-between">
      <b-col sm="12"
        ><b-img
          class="preum-logo-responsive mb-3"
          src="../assets/images/preum-logo.png"
          alt="Logo"
        ></b-img>
        <h2>Welcome to {{ platform_name }}'s Affiliate Program</h2>
      </b-col>
      <b-col cols="12">
        <span>You get 10% off the net fees from everyone you refer.<br><br>To sign up, follow the steps below.
</span
        ></b-col>
    </b-row>
    <b-row>
      <b-col><MainPageWidget /></b-col
    ></b-row>
    <b-row>
      <b-col cols="12" class="mt-3">
        
        <div class="text-center">
          <span>Powered by Preum ❤</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MainPageWidget from "@/components/MainPageWidget.vue";

export default {
  name: "ReferrerSignup",
  components: {
    MainPageWidget,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      platform_name: "SimpleDEFI",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
