/* eslint-disable */
export const isEmailValid = (email) => {
  if (!email.length) return null;
  else if (
    // prettier-ignore // eslint-disable-next-line
    email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) &&
    email.length <= 100
  )
    return true;
  else return false;
};

export const isEmailCodeValid = (code) => {
  if (!code.length) return null;
  else if (/^[A-Za-z0-9]*$/.test(code) && code.length === 6) return true;
  else return false;
};

export const isAddressValid = (address) => {
  const ethAddrCheck = new RegExp("^0x[a-fA-F0-9]{40}$");
  if (!address.length) return null;
  else if (ethAddrCheck.test(address)) return true;
  else return false;
};
