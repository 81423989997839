<template>
  <div>
    <div class="widget-container">
      <b-container>
        <b-row align-v="center">
          <b-col
            ><b-card class="shadow-lg p-3 mb-5 bg-white rounded">
              <DashBoardWidget /></b-card
          ></b-col>
        </b-row>
      </b-container>
    </div>
    <div class="footer-bottom">
      <AppFooter />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DashBoardWidget from "@/components/DashBoardWidget.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: "PartnerDashBoardView",
  components: {
    DashBoardWidget,
    AppFooter,
  },
};
</script>
