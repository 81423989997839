<template>
  <b-container>
    <b-row align-v="center" class="mb-3">
      <b-col lg="6" sm="12"><h1>Go to Your Dashboard</h1></b-col>
      <b-col lg="6" sm="12"
        ><b-img
          class="preum-logo-responsive float-right"
          src="../assets/images/preum-logo.png"
          alt="Logo"
        ></b-img
      ></b-col>
    </b-row>
    <b-row
      ><b-col><DashboardUnlockForm /></b-col
    ></b-row>
    <b-row>
      <b-col cols="12" class="mt-5">
        <div class="text-center">
          <span>Powered by Preum ❤</span>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DashboardUnlockForm from "@/components/DashboardUnlockForm.vue";

export default {
  name: "DashboardUnlock",
  components: {
    DashboardUnlockForm,
  },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
