<template>
  <div>
    <div class="widget-container">
      <b-container>
        <b-row>
          <b-col sm="12"><NavBar /> </b-col>
        </b-row>
        <b-row align-v="center" class="align-middle my-5">
          <b-col sm="12"><MainPage /></b-col>
        </b-row>
      </b-container>
    </div>
    <div class="footer-bottom">
      <AppFooter />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainPage from "@/components/MainPage.vue";
import NavBar from "@/components/NavBar.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: "MainView",
  components: {
    MainPage,
    NavBar,
    AppFooter,
  },
};
</script>

<style scoped>
body {
  min-height: 100vh;
}
</style>
