<template>
  <div class="pt-5 pb-2">
    <b-row>
      <b-col cols="12">
        <div class="my-1 text-center">
          <div>
            <span>
              {{ currentYear }}
              Copyright ©
              <a class="links" target="_blank" href="#!">Preum</a>
            </span>
          </div>
          <div>
            <span
              ><router-link to="/terms">Terms of Service</router-link> |
              <router-link to="/privacypolicy"
                >Privacy Policy</router-link
              ></span
            >
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.footer-bg {
  background: #f7f7ff;
}
</style>
