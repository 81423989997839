<template>
  <div>
    <div>
      <h3>Share your referral link on social media!</h3>
    </div>
    <div class="mt-3 share-network-list">
      <ShareNetwork
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        :style="{ backgroundColor: network.color }"
        :url="sharing.url"
        :title="sharing.title"
        :description="sharing.description"
        :quote="sharing.quote"
        :hashtags="sharing.hashtags"
        :twitterUser="sharing.twitterUser"
      >
        <p class="h1 mb-0 social-icon text-white">
          <b-icon :icon="network.icon"></b-icon>
        </p>
      </ShareNetwork>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialShareWidget",
  data() {
    return {
      networks: [
        {
          network: "twitter",
          name: "Twitter",
          icon: "twitter",
          color: "#1da1f2",
        },
        {
          network: "telegram",
          name: "Telegram",
          icon: "telegram",
          color: "#0088cc",
        },
        {
          network: "reddit",
          name: "Reddit",
          icon: "reddit",
          color: "#ff4500",
        },
        {
          network: "facebook",
          name: "Facebook",
          icon: "facebook",
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "linkedin",
          color: "#007bb5",
        },
        {
          network: "email",
          name: "Email",
          icon: "envelope",
          color: "#333333",
        },
      ],
    };
  },
  computed: {
    sharing() {
      return {
        url: `https://simpledefi.preum.app?r=${this.$store.state.userData.referralCode}`,
        title:
          "Checkout SimpleDEFI - Earn yield on your terms in 3 clicks \n \n Use this link to sign up and earn even more!",
        description:
          "✓ Generate yield on your terms in 3 clicks – anywhere on chain \n ✓ More liquidity & utility for Web3 projects – with very little effort \n ✓ The trustless back office for projects – reduce risk & secure your treasury from outside threats",
        hashtags: "SimpleDefi,Crypto,DEFI,YieldFarming",
        twitterUser: "SimpleDeFi_io",
      };
    },
  },
};
</script>

<style scoped>
a[class^="share-network-"] {
  flex: none;
  color: #ffffff;
  background-color: #333;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 10px 0;
  text-decoration: none;
}
a[class^="share-network-"] .social-icon {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  flex: 0 1 auto;
  color: #ffffff !important;
}
a[class^="share-network-"] span {
  padding: 0 10px;
  flex: 1 1;
  font-weight: 500;
}
.share-network-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
}
@media screen and (max-width: 600px) {
  .share-network-list {
    flex-direction: column;
  }
}
</style>
