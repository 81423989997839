<template>
  <div>
    <div>
      <b-container fluid>
        <b-row class="mb-5">
          <b-col cols="6"
            ><div>
              <b-card title="Number of Referral Partners"> 55 </b-card>
            </div>
          </b-col>
          <b-col cols="6"
            ><div>
              <b-card title="Number of Referral Signups"> 550 </b-card>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card title="Generate Report"
              ><div>
                <b-row>
                  <b-col cols="6">
                    <div>
                      <label for="datepicker-buttons">Select Date Range</label>
                      <b-form-group>
                        <b-form-datepicker
                          id="datepicker-buttons-from"
                          today-button
                          reset-button
                          close-button
                          locale="en"
                          label="From"
                        ></b-form-datepicker>
                        <b-form-datepicker
                          id="datepicker-buttons-to"
                          today-button
                          reset-button
                          close-button
                          locale="en"
                          label="To"
                        ></b-form-datepicker>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col cols="6" class="float-right">
                    <p>Search</p>
                    <b-input v-model="filter"></b-input>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col>
                    <p>Select the fields you'd like for output.</p>
                    <b-checkbox
                      :disabled="visibleFields.length == 1 && field.visible"
                      v-for="field in fields"
                      :key="field.id"
                      v-model="field.visible"
                      inline
                    >
                      {{ field.label }}
                    </b-checkbox>
                    <div class="py-1">
                      <b-button @click="csvExport(csvData)">
                        Export to CSV</b-button
                      >
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div>
                      <b-table
                        :items="items"
                        :fields="visibleFields"
                        :filter="filter"
                        bordered
                        striped
                        fixed
                      >
                      </b-table>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="my-3">
              <p>
                Toggling this switch will disable referral support for your
                platform until you re-enable it.
              </p>
              <div>
                <b-form-checkbox v-model="enabled" name="check-button" switch>
                  Referral System <b>(Enabled: {{ enabled }})</b>
                </b-form-checkbox>
              </div>
            </div>
          </b-col>

          <b-col cols="12" class="mt-5">
            <div class="text-center">
              <span>Powered by Preum ❤</span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminWidget",
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    csvData() {
      return this.users.map((item) => ({
        ...item,
      }));
    },
  },
  methods: {
    // TODO: make csv download work, make dummy data not dummy
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
  },
  mounted() {
    fetch("")
      .then((resp) => resp.json())
      .then((json) => (this.users = json));
  },
  data() {
    return {
      enabled: true,
      filter: "",
      items: [
        // TODO: Need real item datas
        {
          id: 1,
          type: "Referral Partner",
          email: "fred@yahoo.com",
          partnerCode: "123AV",
          wallet: "bsc12",
          referrals: "900",
          referralVolume: "$5000.00",
          feesgenerate: "$240.75",
          estimatedRebate: "$20.47",
        },
        {
          id: 2,
          type: "Referral Partner",
          email: "",
          partnerCode: "BCXY5",
          wallet: "bsc34",
          referrals: "78",
          referralVolume: "$500.00",
          feesgenerate: "$24.75",
          estimatedRebate: "$2.47",
        },
        {
          id: 3,
          type: "User",
          email: "jane@hotmail.com",
          partnerCode: "",
          referralCode: "DSRR6",
          wallet: "bsc56",
          referrals: "0",
          feesPaid: "$500",
          estimatedRebate: "$2.47",
        },
        {
          id: 4,
          type: "Referral Partner",
          email: "bob@gmail.com",
          partnerCode: "LMN98",
          wallet: "bsc78",
          referrals: "6",
          referralVolume: "$500.00",
          feesgenerate: "$24.75",
          estimatedRebate: "$2.47",
        },
      ],
      fields: [
        { key: "id", label: "ID", visible: true },
        { key: "type", label: "User Type", visible: true },
        { key: "email", label: "Email", visible: true },
        { key: "partnerCode", label: "Partner Code", visible: true },
        { key: "referralCode", label: "Referral Code", visible: true },
        { key: "wallet", label: "Wallet Address", visible: true },
        { key: "referrals", label: "Number of Referrals", visible: true },
        { key: "referralVolume", label: "Referral Volume", visible: true },
        { key: "feesGenerated", label: "Fees Generated", visible: true },
        {
          key: "feesPaid",
          label: "Performance Fees Paid",
          visible: true,
        },
        { key: "estimatedRebate", label: "Estimated Rebate", visible: true },
      ],
    };
  },
};
</script>
