<template>
  <div>
    <b-container>
      <b-row align-v="center" class="mb-5">
        <b-col cols="9"
          ><h2>{{ platform_name }} Administrator Dashboard</h2></b-col
        >
        <b-col cols="3"
          ><b-img
            class="preum-logo float-right"
            src="../assets/logo.png"
            alt="Logo"
          ></b-img
        ></b-col>
      </b-row>
      <b-row align-v="center">
        <b-col class="my-3">
          <div class="align-self-center">
            <b-card class="shadow-lg p-3 mb-5 bg-white rounded">
              <AdminWidget
            /></b-card></div
        ></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AdminWidget from "@/components/AdminWidget.vue";

export default {
  name: "AdminPage",
  components: {
    AdminWidget,
  },
  data() {
    return {
      platform_name: "SimpleDEFI",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
