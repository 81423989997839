import Vue from "vue";
import VueRouter from "vue-router";
import MainView from "../views/MainView.vue";
import PartnerDashBoardView from "../views/PartnerDashBoardView.vue";
import DashBoardUnlockView from "../views/DashBoardUnlockView.vue";
import ReferrerSignupView from "../views/ReferrerSignupView.vue";
import UserSignupView from "../views/UserSignupView.vue";
import AdminDashBoardView from "../views/AdminDashBoardView.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TermsService from "../views/TermsService.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    component: MainView,
  },
  {
    path: "/login",
    name: "login",
    component: DashBoardUnlockView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: PartnerDashBoardView,
  },
  {
    path: "/affiliate",
    name: "affiliate",
    component: ReferrerSignupView,
  },
  {
    path: "/admin",
    name: "administrator",
    component: AdminDashBoardView,
  },
  {
    path: "/",
    name: "signup",
    component: UserSignupView,
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms",
    name: "terms",
    component: TermsService,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
