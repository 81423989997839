import Vue from "vue";
import Vuex from "vuex";

import { getDefaultRootState } from "./static/defaultStates";

Vue.use(Vuex);

export default new Vuex.Store({
  state: getDefaultRootState(),
  getters: {
    getFormattedNumber: (num, full = false) => {
      if (isNaN(num)) return "...";
      if (num !== undefined) {
        const sNum = num.toString().split(".");
        if (sNum.length > 1) {
          const remaining = full ? sNum[1] : sNum[1].slice(0, 7);
          const n =
            sNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + remaining;
          // if (n > 80) console.log(n);
          return n;
        } else {
          const n = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          // if (n > 80) console.log(n);
          return n;
        }
      } else {
        return "";
      }
    },
    getLoggedIn: (state) => {
      return state.loggedIn;
    },
  },
  mutations: {
    resetRootState(state) {
      Object.assign(state, getDefaultRootState());
      setTimeout(function () {
        location.reload();
      }, 500);
    },
    setUserData(state, obj) {
      if (obj === null) return;
      // TODO: save userData to localstore
      Object.keys(obj).forEach((key) => {
        state.userData[key] = obj[key];
      });
      localStorage.setItem("userData", JSON.stringify(state.userData));
    },
    setLoggedIn(state, value) {
      state.loggedIn = value;
    },
    setToaster(state, data) {
      state.toaster = data;
    },
    setLogout(state) {
      localStorage.removeItem("userData");
      const defaultState = getDefaultRootState();
      state.userData = defaultState.userData;
    },
  },
  actions: {
    async status({ state }) {
      // console.log("status called");
      return state.httpAPI
        .get(state.baseRoute + "status")
        .then((response) => {
          // console.log("status returned");
          console.log(response);
          // if (response.data.success) {
          // }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    async login({ dispatch, state }, data) {
      // console.log("[index.js](login) data:", data);
      return state.httpAPI
        .post(state.baseRoute + "limit/login", {
          email: data.email,
          confirmCode: data.confirmCode,
        })
        .then((response) => {
          // console.log(response.data);
          if (response.data.success) {
            dispatch("setUserData", {
              sessionToken: response.data.sessionToken,
            });
            setTimeout(function () {
              dispatch("getUserData");
            }, 1000);
          }
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    async logout({ commit, state }) {
      return state.httpAPI
        .post(state.baseRoute + "auth/logout", {
          sessionToken: state.userData.sessionToken,
        })
        .then((response) => {
          // console.log(response.data);
          if (response.data.success) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
          return false;
        })
        .finally(() => commit("setLogout"));
    },
    async signUp({ commit, state }, data) {
      // console.log("data", data);
      return state.httpAPI
        .post(state.baseRoute + "limit/signUp", {
          email: data.email,
          walletAddress: data.walletAddress,
          userRefCode: data.userRefCode,
          refByPlatCode: data.refByPlatCode,
          campaignCode: data.campaignCode,
          agreeTosPp: data.agreeTosPp,
        })
        .then((response) => {
          // console.log("signUp data:", response.data);
          if (response.data.success)
            commit("setUserData", { emailAddress: data.email });

          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getUserData({ commit, state }) {
      var sessionToken = "";
      var sessionTokenCheck = new RegExp("^[a-zA-Z0-9._-]{50,1000}$");
      // console.log(
      //   "[index.js](getUserData) state sessionToken check: ",
      //   !sessionTokenCheck.test(state.userData.sessionToken)
      // );
      if (sessionTokenCheck.test(state.userData.sessionToken)) {
        sessionToken = state.userData.sessionToken;
      } else {
        try {
          const userData = JSON.parse(localStorage.getItem("userData"));
          // console.log("[index.js](getUserData) userData", userData);
          if (userData != undefined && userData != null) {
            const ud = userData;
            sessionToken = ud.sessionToken;
          }
        } catch (error) {
          sessionToken = "";
        }
      }
      // console.log(sessionToken);
      // console.log(
      //   "sessionTokenCheck.test(sessionToken)",
      //   sessionTokenCheck.test(sessionToken)
      // );
      console.log("sessionToken", sessionToken);
      if (sessionTokenCheck.test(sessionToken)) {
        // console.log(sessionToken);
        return (
          state.httpAPI
            .post(state.baseRoute + "auth/getUserData", {
              sessionToken: sessionToken,
            })
            .then((response) => {
              // console.log(response.data);
              if (response.data.success) {
                if (response.data.message) {
                  if (response.data.data != "undefined")
                    // TODO:  returns userDataObj
                    commit("setUserData", response.data.userData);
                  return true;
                }
              }
            })
            // eslint-disable-next-line
            .catch((error) => {
              // eslint-disable-next-line
              // console.log(error);
              return false;
            })
        );
      } else {
        // console.log("[index.js](getUserData) login required");
        return false;
      }
    },
    setToaster({ commit }, data) {
      //data == {content: String, variant: String, title: String}
      commit("setToaster", data);
    },
    setUserData({ commit }, data) {
      if (data === null) return;
      // data == { key: value, key: value}
      // . ^^ always obj single or multi is valid
      commit("setUserData", data);
    },
  },
  modules: {},
});
