<template>
  <div>
    <b-card
    class="partner-link-card text-center py-5"
    text-variant="white"
  ><b-img
          class="preum-logo-responsive"
          src="../assets/images/preum-logo-white.png"
          alt="Logo"
        ></b-img>
  <h2 class="py-3">Earn Yield on Your
Terms in 3 Clicks</h2>
    <b-button variant="primary"
      target="_blank"
      href="https://app.simpledefi.io/?utm_source=preum&utm_content=affiliate-dashboard"
    >
      <div>Start Earning »</div>
    </b-button>
  </b-card>
  </div>
</template>

<script>
export default {
  name: "PartnerCard",
  components: {},
  data() {
    return {
      platform_name: "SimpleDEFI",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
