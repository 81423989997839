<template>
  <b-modal :id="id" :title="title" centered @hidden="onReset" @ok="onSubmit">
    <div>
      <b-form>
        <label for="email-confirm">Input Email Confirmation Code</label>
        <b-form-input
          type="text"
          v-model="form.confirmCode"
          aria-describedby="password-help-block"
        ></b-form-input>
        <b-form-text id="password-help-block">
          We have sent a code to the email you used to generate the link. Input
          the code in the form above to confirm you are the owner of the email
          address.
        </b-form-text>
      </b-form>
    </div>
    <ShowMessage ref="ConfirmCodeModalMessage" />
  </b-modal>
</template>

<script>
import ShowMessage from "@/components/ShowMessage.vue";
export default {
  name: "ConfirmCodeModal",
  components: {
    ShowMessage,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        confirmCode: "",
      },
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.$store
        .dispatch("login", {
          email: this.$store.state.userData.emailAddress,
          confirmCode: this.form.confirmCode,
        })
        .then((response) => {
          // console.log(response);
          if (response.success) {
            this.$router.push("dashboard");
          } else {
            // console.log("SHOW LOGIN ERROR PROMOPT");
            this.$refs["ConfirmCodeModalMessage"].setMessage(
              response.message,
              true
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.$refs["ConfirmCodeModalMessage"].setMessage("error", true);
        });
    },
    onReset(event) {
      event.preventDefault();
      this.form.confirmCode = "";
      this.$bvModal.hide(this.id);
    },
  },
};
</script>
