<template>
  <div>
    <b-container fluid>
      <b-row align-v="center">
        <b-col cols="6"
          ><div class="text-left align-self-center m-3">
            <h1 class="header-color">How the Preum Referral System Works</h1>
            <div class="pl-5 py-4">
              <p>
                <b-icon icon="check2-circle" variant="primary"></b-icon> Users
                create their affiliate referral link and earn rewards for
                sharing your web3 project.
              </p>
              <p>
                <b-icon icon="check2-circle" variant="primary"></b-icon> You can
                reward both affiliate partners and end-users who participate in
                your web3 project via a referral link.
              </p>
              <p>
                <b-icon icon="check2-circle" variant="primary"></b-icon> Get
                unique insight on how your users are discovering your platform
                and where thye are coming from.
              </p>
              <p>
                <b-icon icon="check2-circle" variant="primary"></b-icon>
                Supported platforms are responsible for making reward
                distributions.
              </p>
              <p>
                <b-icon icon="check2-circle" variant="primary"></b-icon> Preum
                ismply aggregates data to deliver an easy to manage referral
                system for crypto and blockchain projects.
              </p>
            </div>
          </div>
          <div class="text-left align-self-center m-3">
            <p class="header-color">
              Get in touch with the Preum team to setup a referral system for
              your Web3 project or platform
            </p>

            <div>
              <b-button
                class="mr-1"
                variant="primary"
                href="mailto:contact@preum.app"
                >Get in Touch</b-button
              >
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <b-img
            class="w-100"
            src="../assets/images/preum-hero-01.png"
            alt="Preum Referral System"
          ></b-img>
        </b-col>
        <!--<b-col class="m-3">
          <div class="align-self-center">
            <b-card class="shadow-lg p-3 mb-5 bg-white rounded">
              <MainPageWidget
            /></b-card></div
        ></b-col>-->
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import MainPageWidget from "@/components/MainPageWidget.vue";

export default {
  name: "MainPage",
  // components: {
  //  MainPageWidget,
  // },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
