<template>
  <div>
    <b-row>
      <b-col>
        <div class="my-3"></div>
      </b-col>
    </b-row>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <!-- <b-form-group
        description="Select the platform to register to your Preum Affiliate Account."
      >
        <template v-slot:label>
          Select Platform
          <span class="text-danger">*</span>
        </template>
        <b-form-select
          v-model="form.platform"
          :options="platforms"
          required
        ></b-form-select>
      </b-form-group> -->
      <NeedHelpTip />
      <b-form-group
        description="In order to create your unique link, please provide your Binance Smart Chain address."
      >
        <template v-slot:label>
          {{ platformSymbol }} {{ addressFormLabel }}
          <span class="text-danger">*</span>
        </template>
        <b-form-input
          v-model="form.address"
          :state="checkAddress"
          type="text"
          placeholder="Enter your BSC wallet address..."
          required
        ></b-form-input>
      </b-form-group>


      <b-form-group
        description="Your email is required to help us prevent spamming, lock your dashboard from view, and to share important news, updates, and information."
      >
        <template v-slot:label>
          {{ emailFormLabel }} <span class="text-danger">*</span>
        </template>
        <b-form-input
          v-model="form.email"
          type="text"
          :state="checkEmail"
          placeholder="Enter Email"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="form.checked"
          :aria-describedby="ariaDescribedby"
          required
        >
          <b-form-checkbox value="checked" required
            >I have read and agree to the
            <router-link to="/terms">Terms of Service</router-link> |
            <router-link to="/privacypolicy">Privacy Policy</router-link>.<span
              class="text-danger"
              >*</span
            ></b-form-checkbox
          >
        </b-form-checkbox-group>
      </b-form-group>
      <div class="d-flex justify-content-between">
        <b-button type="submit" variant="primary">Signup</b-button>
      </div>
    </b-form>
    <ShowMessage ref="MainPageFormMessage" />
    <b-row class="mt-5 d-flex text-center">
      <b-col cols="12"
        ><span>I am already signed up. </span
        ><span
          ><strong
            ><router-link to="/login">Take me to the Dashboard.</router-link>
          </strong></span
        ></b-col
      >
    </b-row>
    <ConfirmCodeModal id="mainPageWidgetModal" title="Email Confirmation" />
  </div>
</template>

<script>
import ConfirmCodeModal from "@/components/ConfirmCodeModal.vue";
import ShowMessage from "@/components/ShowMessage.vue";
import NeedHelpTip from "@/components/NeedHelpTip.vue";
import { isEmailValid, isAddressValid } from "@/store/static/validation";
export default {
  name: "MainPageWidget",
  components: { ConfirmCodeModal, ShowMessage, NeedHelpTip },
  data() {
    return {
      refByPlatCode: null,
      userRefCode: null,
      campaignCode: null,
      form: {
        address: "",
        email: "",
        platform: null,
        checked: [],
      },
      platforms: [
        { text: "Select Your Platform", value: null },
        { text: "SimpleDEFI", value: 2 },
        { text: "Vidulum App", value: 1 },
        { text: "Osmosis Dex", value: 3 },
      ],
      show: true,
      // TODO This might not be optimal way to do the tool tip? Can we set HTML parameters?
      addressFormLabel: "Wallet Address",
      emailFormLabel: "Email",
      platformSymbol: "BSC",
    };
  },
  mounted() {
    // TODO: get ref code and ref public platform code from url
    // if ref public platform code set this.form.platform
    this.sweepURL();
  },
  methods: {
    sweepURL() {
      try {
        // console.log(window.location.host);
        const partner = window.location.host.split(".")[0].toLowerCase();
        switch (partner) {
          case "vidulum":
            this.refByPlatCode = 1;
            break;

          case "simpledefi":
            this.refByPlatCode = 2;
            // this.form.platform = v;
            break;
        }

        const fragments = window.location.search.substr(1).split("&");
        if (fragments) {
          fragments.forEach((frag) => {
            const [k, v] = frag.split("=");
            switch (k) {
              case "p":
                if (this.refByPlatCode === null) this.refByPlatCode = v;
                // this.form.platform = v;
                break;

              case "r":
                this.userRefCode = v;
                break;

              case "c":
                this.campaignCode = v;
                break;
            }
          });
        }
      } catch (err) {
        // console.log(err);
      }
    },
    onSubmit(event) {
      event.preventDefault();
      // alert(JSON.stringify(this.form));
      if (this.checkEmail && this.checkAddress) {
        const data = {
          email: this.form.email,
          walletAddress: this.form.address,
          userRefCode: this.userRefCode,
          refByPlatCode: this.refByPlatCode,
          campaignCode: this.campaignCode,
          agreeTosPp: true,
        };
        this.$store
          .dispatch("signUp", data)
          .then((result) => {
            // console.log(result);
            if (result.success) this.$bvModal.show("mainPageWidgetModal");
            else
              this.$refs["MainPageFormMessage"].setMessage(
                result.message,
                true
              );
          })
          .catch((error) => console.log(error));
      } else {
        this.$refs["MainPageFormMessage"].setMessage(
          "Invalid Email or Address",
          true
        );
      }
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.refByPlatCode = null;
      this.userRefCode = null;
      this.campaignCode = null;
      this.form.email = "";
      this.form.address = "";
      this.form.platform = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
  computed: {
    checkEmail() {
      return isEmailValid(this.form.email);
    },
    checkAddress() {
      return isAddressValid(this.form.address);
    },
  },
};
</script>
