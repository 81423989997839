<template>
  <div>
    <b-container>
      <b-row align-v="center">
        <b-col
          ><nav>
            <router-link to="/"
              ><p><b>Back to Main Page</b></p></router-link
            >
          </nav></b-col
        >
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "BackHome",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
