<template>
  <div>
    <div class="widget-container">
      <b-container>
        <b-row align-v="center">
          <b-col
            ><b-card class="shadow-lg p-3 mb-5 bg-white rounded">
              <ReferrerSignup /> </b-card
          ></b-col>
        </b-row>
      </b-container>
    </div>
    <div class="footer-bottom">
      <AppFooter />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import ReferrerSignup from "@/components/ReferrerSignup.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: "ReferrerSignupView",
  components: {
    ReferrerSignup,
    AppFooter,
  },
};
</script>
