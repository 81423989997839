<template>
  <div>
    <span>Login To View Your Dashboard</span>

    <b-form-group
      class="my-3"
      description="Your email is used to unlock your dashboard."
    >
      <template v-slot:label>
        {{ emailFormLabel }}
        <span class="text-danger">*</span>
      </template>
      <b-form-input
        v-model="form.email"
        type="text"
        placeholder="Enter Email"
        :state="checkEmail"
        required
      ></b-form-input>
    </b-form-group>
    <b-form @submit="onSubmit" @reset="onReset" class="my-2">
      <b-form-group
        description="Use the confirm code previously sent to your email. Click here for a new code."
      >
        <template v-slot:description>
          <span>Use the confirm code previously sent to your email.</span
          ><b-link :disabled="sendCodeBtnDisabled" @click="confirmAddress()">
            Click here for a new code.</b-link
          >
        </template>
        <template v-slot:label>
          Enter Confirm Code
          <span class="text-danger">*</span>
        </template>
        <b-input-group>
          <b-form-input
            v-model="form.confirmCode"
            type="text"
            placeholder="Enter Confirmation Code From Your Email"
            :state="checkCode"
            required
          />
        </b-input-group>
      </b-form-group>

      <b-button type="submit" variant="primary">Go To Dashboard</b-button>
    </b-form>

    <ShowMessage ref="DashboardUnlockFormMessage" />
    <b-row class="mt-5 d-flex text-center">
      <b-col cols="12"
        ><span>I do not have an affiliate account. </span
        ><span
          ><strong><router-link to="/">Signup.</router-link> </strong></span
        ></b-col
      >
    </b-row>
    <ConfirmAddressModal
      id="dashboardUnlockFormModal"
      title="Address Confirmation"
      :email="this.form.email"
      :symbol="this.platformSymbol"
    />
  </div>
</template>

<script>
import { isEmailValid, isEmailCodeValid } from "@/store/static/validation";
import ConfirmAddressModal from "@/components/ConfirmAddressModal.vue";
import ShowMessage from "@/components/ShowMessage.vue";

export default {
  name: "DashboardUnlockForm",
  components: {
    ShowMessage,
    ConfirmAddressModal,
  },
  data() {
    return {
      form: {
        email: "",
        confirmCode: "",
      },
      state: {
        email: null,
        confirmCode: null,
      },
      sendCodeBtnDisabled: false,
      emailFormLabel: "Email",
      platformSymbol: "BSC",
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.checkEmail && this.checkCode) {
        const data = {
          email: this.form.email,
          confirmCode: this.form.confirmCode,
        };
        this.$store
          .dispatch("login", data)
          .then((result) => {
            // console.log(result);
            if (result.success) {
              this.$router.push("dashboard");
            } else {
              // console.log("SHOW LOGIN ERROR PROMOPT");
              this.$refs["DashboardUnlockFormMessage"].setMessage(
                result.message,
                true
              );
            }
          })
          .catch((error) => console.log(error));
      } else {
        this.$refs["DashboardUnlockFormMessage"].setMessage(
          "Invalid Email or Address",
          true
        );
      }
    },
    confirmAddress() {
      // console.log("this.checkEmail", this.checkEmail);
      if (this.checkEmail) {
        this.sendCodeBtnDisabled = true;
        this.$bvModal.show("dashboardUnlockFormModal");
      } else {
        // console.log("dashboardUnlockFormModal ERROR");
        this.$refs["DashboardUnlockFormMessage"].setMessage(
          "Valid Email Address is Required",
          true
        );
      }
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.confirmCode = "";
      this.state.email = null;
      this.state.confirmCode = null;
      this.sendCodeBtnDisabled = false;
    },
  },
  computed: {
    checkEmail() {
      return isEmailValid(this.form.email);
    },
    checkCode() {
      return isEmailCodeValid(this.form.confirmCode);
    },
  },
};
</script>
