<template>
  <div>
    <b-container fluid>
      <b-row align-v="center">
        <b-col cols="12">
          <div class="text-center mb-5">
            
            <h1>Terms of Service</h1>
            <b-img
            class="preum-logo"
            src="../assets/images/preum-app.png"
            alt="Logo"
          ></b-img
        >
          </div>
        </b-col>
        <b-col cols="12">
          <div class="text-left mt-5">
            <h3>Last updated on: December, 2022</h3>
            <h4 class="mt-3">
              BEFORE USING OUR SITES, PLEASE READ THE TERMS OF SERVICE CAREFULLY.
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="text-left">
            <p>
              Preum (together with its affiliates and subsidiaries, "we", "us", "our" and terms of similar meaning) provides this Website, 
              https://preum.app (this site and any software or services provided by the PREUM through this site, it’s subdomains and other links, the "Site") 
              to you subject to these terms of use (these "Terms"). In these terms we describe users ("Users") of the Site as both registered and unregistered users.
              <br/><br/>
              Please read these Terms carefully before using the Site. By accessing, using or browsing the Site you agree to be legally bound by these Terms and all terms, 
              policies and guidelines incorporated by reference in these Terms. If you do not agree with these Terms in their entirety, you may not use the Site and may 
              choose to exit the Website.<br/><br/>
              Preum reserves the right to change or modify any of the terms and conditions contained in these Terms, or any policy or guideline of the Site, at any time 
              and in its sole discretion. If we do so, we will make notifications through our Discord Community and any other means of public communication Preum deems fit, 
              if any. If you do not agree with the changes, you can cancel your account with us without further obligation.<br/><br/>
              The services we provide through the Site are for your own use only. You may not resell, lease or provide them in any other way to anyone else.<br/><br/>
              If you register for an account on the Site, you agree to (a) provide accurate, current and complete information as may be prompted by any registration forms 
              on the Site ("Registration Data"); (b) maintain the security of your password; ( c) maintain and promptly update the Registration Data, and any other 
              information you provide to the Site, and to keep it accurate, current and complete; and (d) accept all risks of unauthorized access to the Registration 
              Data and any other information you provide to the Site. You are responsible for all activity on your Site account. Preum reserves the right to limit access 
              to certain features or services to active users of its web applications and any other services or offerings.<br/><br/>
              In these Terms the content on the Site, including all information, data, logos, marks, designs, graphics, pictures, sound files, other files, and their 
              selection and arrangement, is called "Content".<br/><br/>
              the Site, all Content and all software available on the Site or used to create and operate the Site is the property of Preum or its licensors, and is 
              protected by United States of America and international copyright laws, and all rights to the Site, such Content and such software are expressly reserved. 
              All trademarks, registered trademarks, product names and company names or logos mentioned in the Site are the property of their respective owners. 
              Reference to any products, services, processes or other information (by trade name, trademark, manufacturer, supplier or otherwise) does not constitute or 
              imply endorsement, sponsorship or recommendation thereof by Preum.<br/><br/>
              Preum grants you a limited, revocable, non-exclusive, non-sub-licensable license to access the Site and to view, copy and print portions of the Content 
              available to you on the Site. User may not attempt to reverse engineer the site, sites, services or contents. In it's sole discretion, Preum may revoke 
              support for third party projects, coin listings and token listings in it's web application as well as any other services or features it chooses at any time, 
              without notice.<br/><br/>
              Except as expressly permitted above, any use of any portion of the Content without the prior written permission of its owner is strictly prohibited and will 
              terminate the license granted in this Agreement and your account with us.<br/><br/>
              You represent and warrant that your use of the Site and the Content will be consistent with this license and will not infringe or violate the rights of any 
              other party or breach any contract or legal duty to any other parties, or violate any applicable law.<br/><br/>
              The Site may contain links to third-party Web sites ("Third-Party Sites") and third-party content ("Third-Party Content") as a service to those interested 
              in this information. You use links to Third-Party Sites, and any Third-Party Content or service provided there at your own risk. Preum does not monitor or 
              have any control over, and makes no claim or representation regarding, Third-Party Content or Third-Party Sites.<br/><br/>
              While Preum attempts to make your access to and use of the Site safe, Preum does not represent or warrant that the Site or any Content are free of viruses or 
              other harmful components.<br/><br/>
              Preum services are provided "as is" and as a convenience. Preum provides affiliate marketing aggregation services to Third Part Partners. In using Preum service 
              feature, you hereby agree that the respective terms of service, conditions, privacy and any other use policies of those third party service providers shall apply.<br/><br/>
              These third party use agreements can be found at the following web addresses:
              <ul><li><a href="https://simpledefi.io" target="_blank" rel="noopenner">SimpleDEFI</a></li></ul>
              Preum services do not require users to connect blockchain wallet, send or receive funds. The affiliate platform merely aggregates data for our business customers. Preum makes 
              no promise of rewards. Any and all distributions of rewards related to the use of Preum services are the sole responsibility of the respective platform User has chosen to associate their account with.
              <br/><br/>Preum reserves the right to discontinue support for any third party service at any time, without notice.<br/><br/>
              ----- You shall defend, indemnify and hold harmless Preum and the other Released Parties from any loss, damages, liabilities, costs, expenses, claims and 
              proceedings arising out of your use of the Site and from the use of the Site by any person to whom you give access to your account, including any claims made 
              by any person that any of your User Content infringes the rights, including the intellectual property rights, of any third party.<br/><br/>
              The Site is controlled by Preum. You and Preum both benefit from establishing a predictable legal environment in regard to the Site. Therefore, you and the Preum 
              explicitly agree that all disputes, claims or other matters arising from or relating to your use of the Site will be governed by the laws of the State of Utah and 
              the federal laws of the United States of America applicable therein. The United Nations Convention on Contracts for the International Sale of Goods does not apply to 
              these Terms.<br/><br/>
              If any provision of these Terms shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not 
              affect the validity and enforceability of any remaining provisions. Preum may assign any or all of its rights hereunder to any party without your consent. You are not 
              permitted to assign any of your rights or obligations hereunder without the prior written consent of the Preum, and any such attempted assignment will be void and 
              unenforceable.<br/><br/>
              These Terms constitute the entire agreement between you and Preum regarding your use of the Site, and supersedes all prior or contemporaneous communications 
              whether electronic, oral or written between you and Preum regarding your use of the Site.
            </p>
            </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyText",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
