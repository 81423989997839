<template>
  <div id="nav">
    <b-container fluid>
      <b-navbar toggleable="md" class="mb-3" type="light">
        <b-nav-toggle target="nav_collapse"></b-nav-toggle>
        <b-navbar-brand href="#"
          ><b-img
            class="preum-logo"
            src="../assets/images/preum-logo.png"
            alt="Logo"
          ></b-img
        ></b-navbar-brand>
        <b-collapse is-nav id="nav_collapse">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item to="/" exact exact-active-class="active"
              >Main View</b-nav-item
            >

            <b-nav-item to="/affiliate" exact exact-active-class="active"
              >Affiliates</b-nav-item
            >

            <b-nav-item to="/signup" exact exact-active-class="active"
              >Signup New User</b-nav-item
            >

            <b-button to="/dashboard" variant="primary">Dashboard</b-button>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style scoped></style>
