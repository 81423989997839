<template>
  <div>
    <b-row align-v="start" class="mb-3">
      <b-col lg="8" sm="12">
        <b-img
          class="preum-logo-responsive"
          src="../assets/images/preum-logo.png"
          alt="Logo"
        ></b-img>
        <h2>Dashboard</h2>
        <p>
          You now get a 10% rebate on all SimpleDEFI transaction fees.<br /><br />
          
          Share your Affiliate link to earn 10% of all net fees generated from everyone you refer.
        </p>
        <div class="mt-5 mb-3">
          <h6>
            <div v-if="$store.state.userData.walletAddress">
              {{ platformSymbol }} Address: {{ walletAddress }}
            </div>
            <div v-else class="pl-2">
              {{ platformSymbol }} Address:
              <b-skeleton animation="wave" width="15rem"></b-skeleton>
            </div>
          </h6>
        </div>
      </b-col>

      <b-col v-show="$store.state.showFullDash" lg="4" sm="12">
        <PartnerCard />
      </b-col>
    </b-row>

    <div v-if="!$store.state.showFullDash">
      <b-col lg="12">
        <ChoiceComponent />
      </b-col>
    </div>

    <div v-else>
      <b-row>
        <b-col lg="12">
          <div class="mt-4">
            <h4 class="mb-2">{{ platformName }} Referral Link:</h4>
            <div
              class="referral-link-copy bg-light align-items-center p-2 border border-info rounded-sm"
            >
              <div>
                <h5 class="text-primary cursor-hover mb-0">
                  <div v-if="$store.state.userData.referralCode">
                    {{ referralLink }}
                  </div>
                  <div v-else>
                    <b-skeleton
                      animation="wave"
                      width="15rem"
                      height="50%"
                    ></b-skeleton>
                  </div>
                </h5>
              </div>
              <div>
                <b-btn
                  class="align-middle"
                  variant="secondary"
                  @click.stop.prevent="copyreferralLink"
                >
                  Copy <b-icon icon="files" aria-hidden="true"></b-icon>
                </b-btn>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <div>
        <SocialShareWidget />
      </div>
      <b-row class="my-5" align-v="center">
        <b-col cols="12">
          <h4>Affiliate Stats - {{ platformName }}</h4>
        </b-col>
        <b-col lg="4" sm="12" md="12" class="mb-2"
          ><div class="align-self-center">
            <b-card
              border-variant="primary"
              header-bg-variant="primary"
              header-text-variant="white"
              header="# of Referrals"
            >
              <!-- <div v-if="$store.state.userData.totalReferrals"> -->
              {{ $store.state.userData.totalReferrals }}
              <!-- </div>
            <div v-else>
              <b-skeleton-icon
                icon="three-dots"
                class="m-0 p-0"
                :icon-props="{
                  fontScale: 2,
                  variant: 'dark',
                  animation: 'cylon',
                }"
              />
            </div> -->
            </b-card>
          </div>
        </b-col>
        <!-- Commented out until Data Feed Completed
        <b-col lg="4" sm="12" md="12" class="mb-2"
        ><div>
          <b-card
            border-variant="primary"
            header-bg-variant="primary"
            header-text-variant="white"
            header="Referral Volume"
            ><div v-if="txVolume">
              {{ txVolume }}
            </div>
            <div v-else>
              <b-skeleton-icon
                icon="three-dots"
                class="m-0 p-0"
                :icon-props="{
                  fontScale: 2,
                  variant: 'dark',
                  animation: 'cylon',
                }"
              />
            </div>
          </b-card>
        </div>
      </b-col>
      <b-col lg="4" sm="12" md="12" class="mb-2"
        ><div>
          <b-card
            border-variant="primary"
            header-bg-variant="primary"
            header-text-variant="white"
            header="Lifetime Fees Generated"
          >
            <div v-if="feesGenerated">
              {{ feesGenerated }}
            </div>
            <div v-else>
              <b-skeleton-icon
                icon="three-dots"
                class="m-0 p-0"
                :icon-props="{
                  fontScale: 2,
                  variant: 'dark',
                  animation: 'cylon',
                }"
              />
            </div>
          </b-card>
        </div>
      </b-col>-->
        <!-- <b-col cols="12"
          ><div>
            <b-card
              class="mb-4"
              border-variant="secondary"
              header-bg-variant="secondary"
              header-text-variant="white"
              header="Estimated Affiliate Rewards Pending"
            >
              <p><strong>Pending Rewards:</strong> {{ feesGenerated }}</p>

              <p><strong>Last Updated:</strong> {{ lastUpdated }}</p>
            </b-card>
          </div></b-col
        > -->
        <!-- <b-row class="my-5">
        <b-col cols="12">
          <h4>Referral Rewards</h4>
          <p>
            Your estimated rewards from using a referral link to access
            {{ platformName }}
          </p>
        </b-col>
        <b-col cols="4" class="mb-4"
          ><div>
            <b-card
              border-variant="blue"
              header-bg-variant="blue"
              header-text-variant="white"
              header="Transaction Volume"
            >
              {{ userTransactionVolume }}
            </b-card>
          </div>
        </b-col>
        <b-col cols="4" class="mb-4"
          ><div>
            <b-card
              border-variant="blue"
              header-bg-variant="blue"
              header-text-variant="white"
              header="Total Fees"
            >
              {{ userTotalFees }}
            </b-card>
          </div>
        </b-col>
        <b-col cols="4" class="mb-4"
          ><div>
            <b-card
              border-variant="blue"
              header-bg-variant="blue"
              header-text-variant="white"
              header="Total Savings"
            >
              {{ userTotalSavings }}
            </b-card>
          </div>
        </b-col>
        <b-col cols="12"
          ><div>
            <b-card
              class="mb-4"
              border-variant="secondary"
              header-bg-variant="secondary"
              header-text-variant="white"
              header="Estimated Referral Rewards Pending"
            >
              <p>
                <strong>Estimated Pending Rebate:</strong>
                {{ userPendingRebate }}
              </p>

              <p><strong>Last Updated:</strong> {{ userLastUpdated }}</p>
            </b-card>
          </div></b-col
        >
      </b-row> -->

        <b-col lg="8" sm="12">
          <div>
            <b-card
              class="mb-5"
              bg-variant="dark"
              header-text-variant="white"
              :header="`${platformName} Affiliate Program Rules`"
            >
              <p class="text-white program-rules">{{ programRules }}</p>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-col lg="12" sm="12" class="text-center"
      ><b-btn class="mr-2" variant="info" @click="logout()">
        Logout
        <b-icon
          icon="box-arrow-right"
          font-scale="1"
          aria-hidden="true"
        ></b-icon> </b-btn
    ></b-col>
    <!-- <div>
      <SocialShareWidget />
    </div> -->
  </div>
</template>

<script>
import SocialShareWidget from "@/components/SocialShareWidget.vue";
import ChoiceComponent from "@/components/ChoiceComponent.vue";
import PartnerCard from "@/components/PartnerCard.vue";

export default {
  name: "DashBoardWidget",
  components: {
    SocialShareWidget,
    PartnerCard,
    ChoiceComponent,
  },
  data() {
    return {
      platformSymbol: "BSC",
      feesGenerated: "",
      txVolume: "",
      pendingReward: "$2.475",
      lastUpdated: "2022-11-06",
      platformName: "SimpleDEFI",
      userTotalSavings: "$10.00",
      userTransactionVolume: "56",
      userTotalFees: "$100.00",
      userPendingRebate: "$10.00",
      userLastUpdated: "2022-11-13",
      programRules:
        "Refer people and receive 10% of the fees they generate. They will earn a 10% rebate on fees if you they use your referral code! \n \n Your referral fees are calculated on the net fees generated by the user you referred.\n \n SimpleDEFI reserves the right to alter or cancel this arrangement at any time.",
    };
  },
  methods: {
    logout() {
      // console.log("logout");
      this.$store.dispatch("logout").then(() => this.$router.push("/"));
    },
    copyreferralLink() {
      navigator.clipboard.writeText(this.referralLink);
    },
    // n is char on each side of ...
    // n = 4 return abcd..wxyz
    midEllipsis: (str, n) => {
      return str.length >= n * 2 + 3
        ? str.substring(0, n) + "..." + str.substring(str.length - n)
        : str;
    },
  },
  computed: {
    walletAddress() {
      return this.midEllipsis(this.$store.state.userData.walletAddress, 8);
    },
    referralLink() {
      return `https://simpledefi.preum.app?r=${this.$store.state.userData.referralCode}`;
    },
  },
};
</script>
<style scoped>
.referral-link-copy {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.right-column-buttons {
  text-align: right;
}
.program-rules {
  white-space: pre-wrap;
}

@media screen and (max-width: 600px) {
  .referral-link-copy {
    display: block;
    text-align: center;
  }
  .right-column-buttons {
    text-align: left;
  }
}
</style>
