<template>
  <b-modal :id="id" :title="title" centered @hidden="onReset">
    <div>
      <b-form>
        <label>Input {{ symbol }} Address</label>
        <b-form-input
          type="text"
          v-model="form.address"
          :state="checkAddress"
        ></b-form-input>
        <b-form-text> We will resend a code to your email. </b-form-text>
      </b-form>
    </div>
    <ShowMessage ref="ConfirmAddressModalMessage" />
    <template #modal-footer>
      <b-button @click="onReset"> Close </b-button>
      <b-button
        :disabled="disableSendEmailBtn"
        @click="onSubmit"
        variant="primary"
      >
        Send New Code
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { isAddressValid } from "@/store/static/validation";
import ShowMessage from "@/components/ShowMessage.vue";
export default {
  name: "ConfirmAddressModal",
  components: {
    ShowMessage,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    symbol: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        address: "",
      },
      disableSendEmailBtn: false,
    };
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      // console.log("this.checkAddress", this.checkAddress);
      // console.log(this.email);
      if (this.checkAddress) {
        return this.$store.state.httpAPI
          .post(this.$store.state.baseRoute + "limit/sendNewCode", {
            email: this.email,
            address: this.form.address,
          })
          .then((response) => {
            // console.log(response.data.message);
            this.$refs["ConfirmAddressModalMessage"].setMessage(
              response.data.message
            );
            this.disableSendEmailBtn = true;
          })
          .catch((error) => {
            console.log(error);
            this.$refs["ConfirmAddressModalMessage"].setMessage("error", true);
            // this.$bvModal.hide(this.id);
          });
      } else {
        // console.log("ConfirmAddressModalMessage ERROR");
        this.$refs["ConfirmAddressModalMessage"].setMessage(
          "Valid Address is Required",
          true
        );
        // this.$bvModal.hide(this.id);
      }
    },
    onReset(event) {
      event.preventDefault();
      this.form.address = "";
      this.$bvModal.hide(this.id);
    },
  },
  computed: {
    checkAddress() {
      return isAddressValid(this.form.address);
    },
  },
};
</script>
