// import { Languages } from "../assets/js/languages";
import axios from "axios";

export const getDefaultRootState = () => {
  return {
    baseRoute: "https://api.preum.app/",
    httpAPI: axios.create(),
    toaster: { content: "", variant: "" },
    showFullDash: false,
    currentCoin: "",
    loggedIn: false,
    userData: {
      sessionToken: "",
      emailAddress: "",
      walletAddress: "",
      totalReferrals: 0,
      referralCode: "",
    },
  };
};
