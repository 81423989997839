<template>
  <div class="widget-container">
    <b-container>
      <TermsServiceText />
      <div class="text-center mt-5">
        <BackHome />
      </div>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import TermsServiceText from "@/components/TermsServiceText.vue";
import BackHome from "@/components/BackHome.vue";

export default {
  name: "AboutView",
  components: {
    TermsServiceText,
    BackHome,
  },
};
</script>
