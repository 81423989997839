<template>
  <div>
    <b-row align-v="center" class="mb-3 d-flex justify-content-between">
      <b-col cols="6"><PartnerCard /></b-col>
      <b-col cols="6">
        <div>
          <b-card class="preum-link-card text-center py-5" text-variant="white">
            <b-img
          class="preum-logo-responsive"
          src="../assets/images/empty-png.png"
          alt="Logo"
        ></b-img>
            <h2 class="py-3">Affiliates Earn 10% of Fees Generated</h2>
            <b-button
              variant="orange"
              @click="setShowFullDash()"
              text-variant="white"
            >
              <div>Become an Affiliate $</div>
            </b-button>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PartnerCard from "@/components/PartnerCard.vue";

export default {
  name: "ChoiceComponent",
  components: {
    PartnerCard,
  },
  data() {
    return {};
  },
  methods: {
    setShowFullDash() {
      this.$store.state.showFullDash = true;
      localStorage.setItem("showFullDash", 1);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
