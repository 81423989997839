<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    try {
      const showFullDash = localStorage.getItem("showFullDash");
      if (showFullDash) this.$store.state.showFullDash = true;
    } catch (error) {
      //console.log(error);
    }

    this.$store
      .dispatch("getUserData")
      .then((success) => {
        // console.log(success);
        if (success && this.$route.name != "dashboard")
          this.$router.push("dashboard");
        else if (!success && this.$route.name == "dashboard")
          this.$router.push("/");
      })
      .catch((error) => console.log(error));
    // this.$store.dispatch("status");
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #0047b8;
    }
  }
}
</style>
