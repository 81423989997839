<template>
  <div>
    <b-container>
      <b-row>
        <b-col sm="12"><NavBar /> </b-col>
      </b-row>
      <b-row align-v="center" class="align-middle">
        <b-col sm="12"><AdminPage /></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import AdminPage from "@/components/AdminPage.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "AdminDashBoardView",
  components: {
    AdminPage,
    NavBar,
  },
};
</script>

<style scoped>
body {
  max-height: 100vh !important;
}
</style>
