<template>
  <div class="text-right w-100 mb-1">
    <div>
      <div v-b-tooltip.hover.left :title="toolTip" variant="primary">
        <span
          ><strong
            >Need Help
            <b-icon icon="question-square" variant="secondary"></b-icon
          ></strong>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NeedHelpTip",
  data() {
    return {
      // TODO This might not be optimal way to do the tool tip? Can we set HTML parameters?
      toolTip:
        "To participate in the SimpleDEFI program, you'll need to provide your Binance Smart Chain (BSC) address. Learn more about BSC at: https://bnbchain.org/en.",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
