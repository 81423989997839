<template>
  <div>
    <b-container fluid>
      <b-row align-v="center">
        <b-col cols="12">
          <div class="text-center mb-5">
            
            <h1>Privacy Policy</h1>
            <b-img
            class="preum-logo"
            src="../assets/images/preum-app.png"
            alt="Logo"
          ></b-img
        >
          </div>
        </b-col>
        <b-col cols="12">
          <div class="text-left mt-5">
            <h3>LAST UPDATE OF THIS PRIVACY POLICY - December, 2022</h3>
            <h4 class="mt-3">
              BEFORE USING OUR SITES, PLEASE READ THIS PRIVACY POLICY CAREFULLY.
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="text-left">
            <p>
              This Privacy Policy is applicable to Preum App and sets out our
              policy on the gathering and use of information on this Website
              https://preum.app and our other sites (this site and any software
              or services provided by Preum through this site, it’s subdomains
              and other links, collectively "Sites"). Preum is committed to
              providing safe websites for visitors and has implemented this
              Privacy Policy to demonstrate our firm commitment to your privacy.
              Preum complies with US privacy laws and regulations.<br /><br />
              There may be links from our Sites to other websites; note that
              this Privacy Policy applies only to our Sites and not to web sites
              of other companies or organizations to which our Sites may be
              linked. You must check on any linked sites for the privacy policy
              that applies to that site and/or make any necessary inquiries in
              respect of that privacy policy with the operator of the linked
              site. These links to third party websites are provided as a
              convenience and are for informational purposes only. Preum
              does not endorse, and is not responsible for, these linked
              websites.<br /><br />You are required to register to access some
              of our Sites and you may be asked to provide us with personal
              information when you visit certain sections of our Sites. Your use
              of our Sites signifies your acknowledgement and consent to our
              Privacy Policy. If you do not agree to this Privacy Policy, please
              do not continue to use our Sites. Your continued use of the Sites
              signifies your acceptance of these terms and any changes in effect
              at the time of use.
            </p>
            <h4 class="mt-5">COLLECTION OF PERSONAL INFORMATION</h4>
            <p>
              Personal Information is information about you that identifies you
              as an individual, for example, your name, address, e-mail address,
              or telephone number, blockchain based digital addresses.<br /><br />
              We collect information that you voluntarily provide to us through
              registration to our online services, use or view our website via
              your browser’s cookies, responses to surveys, search functions,
              questionnaires, feedback, contests, and the like. We may also ask
              you to provide additional information if you want to obtain
              additional services, information, participate in a contest or to
              resolve complaints or concerns.<br /><br />
              <b>Preum collects the following data:</b>
              
              <ul>
                <li>Personal identification information (blockchain based digital addresses, email address,
              etc.)</li>
              <li>Preum employs the use of Google Analytics tools and other webiste analytics services.</li>
              </ul>
            </p>
            <h4 class="mt-5">HOW DOES PREUM USE INFORMATION GATHERED ABOUT ONLINE VISITORS?</h4>
            <p>Before forwarding us any personal information, please be advised that any information gathered on our Sites 
                is necessary to provide Preum services and may also be used for research and development relating to our Sites 
                and/or for future site development and, if you ask us to send you promotional materials.<br/><br/> 
                In particular, we may use information gathered about you for the following purposes: to 
                monitor interest in our products and to assist us to tailor the content of our Sites to your needs by collecting information about your 
                preferences through tracking of patterns page views on our Sites; to create a profile relating to you in order to show you the content that 
                might be of interest to you and to display the content according to your preferences; and, in circumstances where you have indicated that you 
                wish to receive additional information, to send you information about us and promotional material about our products together with details of 
                any offers we may have available from time to time.<br/><br/>
                <b>Preum collects your data so that we can:</b>
                
                <ul>
                    <li>Provide Preum Services, such as the affiliate program.</li>
                    <li>To provide support and advise of product updates.</li>
                    <li>Make improvements to sites and services.</li>
                    <li>Email you with special offers on other products and services we think you might like.</li>
                    <li>Marketing and Advertising purposes.</li>
                </ul>
                Preum will keep user data for the duration of site use and at least seven years thereafter. 
                Once this time period has expired, we may delete your data by destroying all records of it in Preum’s possession.</p>
                <h4 class="mt-5">PROMOTIONAL AND INFORMATIONAL OFFERS</h4>
                <p>Information submitted at the time of registration may be used for 
                    marketing and promotional purposes by Preum and it's Third Party Partners with which your account is being associated. When users make use of the Preum web 
                    application they must select a third party service, provide a blockchain based digital address, and an email. This information will be shared with the respective third party 
                    in order for them to process affiliate rewards, notify users of changes to their Preum Affiliate program. At their discretion and outside of Preum's control, these third parties
                     may also use this information for marketing and promotional purposes.<br/><br/>
                    Notice of the collection and use of this information is necessary for Preum services to work and this fact is made available upon registration to the email you provided.<br/><br/>
                    If a visitor objects to such use for any reason, he/she may prevent that use, either by e-mail request or by modifying the registration information provided. However, in doing so, 
                    Preum services may not function as intended, or at all.<br/><br/>
                    Preum uses reasonable efforts to maintain visitors' information in a secure environment. If you have submitted personal information 
                    and want to change it or opt-out, please contact us as described below.</p>
                <h4 class="mt-5">DISCLOSURE OF INFORMATION</h4>
                <p>Preum will not disclose personal information that you provide on its Sites to any third parties (except those with which you have chose to associate your account with) 
                or other than to a Preum agent except: i) in accordance with the terms of this Privacy Policy, or ii) to comply with legal requirements such as a law, regulation, warrant, 
                subpoena or court order, and/or iii) if you are reporting an adverse event/side effect, in which case Preum may be required to disclose such information to bodies such as, 
                but not limited to, United States and/or international regulatory authorities. Please note that any of these disclosures may involve the storage or processing of personal 
                information outside of the United States and may therefore be subject to different privacy laws than those applicable in the United States, including laws that require the 
                disclosure of personal information to governmental authorities under circumstances that are different than those that apply in the United States of America.</p>
                <h4 class="mt-5">
                    THIRD PARTY PARTNERS
                </h4>
                <p>Preum provides services to third party partners who may require the information you provide on registration in order to complete their services. In order to fully understand 
                    how they use your date, please visit the partner platforms privacy policy.<br/><br/>
                    <b>Partner Platform Privacy Policies</b>
                    <ul><li><a href="https://www.simpledefi.io/" target="_blank" rel="noopenner">SimpleDEFI</a></li></ul>
                    </p>
                <h4 class="mt-5">COOKIES</h4>
                <p>Preum, in common with many web site operators, may use standard technology called "cookies" on its Sites. Cookies are small data files that are downloaded onto your computer when 
                    you visit a particular web site. You can disable cookies by turning them off in your browser; however, some areas of the Sites may not function properly if you do so.<br/><br/>
                    <b>How do we use cookies?</b>
                    <ul>
                        <li>Keeping you signed in</li>
                        <li>Understanding how you use our website</li>
                        <li>Provide services</li>
                        <li>Google Analytics</li>
                    </ul>
                    Google Analytics' does currently allows opt-outs for the web.</p>
                    <h4 class="mt-5">PROTECTION OF CHILDREN ONLINE</h4>
                    <p>Preum considers the protection of children's privacy, especially online, to be of the utmost importance. We do not knowingly collect or solicit personal information from children nor do we knowingly allow them to become registered users of, or to request information through, our Sites or help-seeking information lines.</p>
                    <h4 class="mt-5">POLICY CHANGE:</h4>
                    <p>If we alter our Privacy Policy, any changes will be posted on this page of our Site so that you are always informed of the information we collect about you, how we use it and the circumstances under which we may disclose it.</p>
                    <h4 class="mt-5">ACCEPTANCE OF OUR PRIVACY POLICY:</h4>
                    <p>By using this Site or any other Preum Site or services, you signify your acceptance of our Privacy Policy, and you adhere to the terms and conditions posted on the Site. By submitting your information, you agree that it will be governed by our Privacy Policy.</p>
                    <h4 class="mt-5">FOR EU CITIZENS</h4>
                    <p>
                        <b>What are your data protection rights?</b><br/><br/>
                        Preum would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
<ul>
    <li>The right to access – You have the right to request Preum for copies of your personal data. We may charge you a small fee for this service.</li>
        <li>The right to rectification – You have the right to request that Preum correct any information you believe is inaccurate. You also have the right to request Preum to complete the information you believe is incomplete.</li>
            <li>The right to erasure – You have the right to request that Preum erase your personal data, under certain conditions.</li>
                <li>The right to restrict processing – You have the right to request that Preum restrict the processing of your personal data, under certain conditions.</li>
                    <li>The right to object to processing – You have the right to object to Preum’s processing of your personal data, under certain conditions.</li>
                        <li>The right to data portability – You have the right to request that Preum transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
</ul>
If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: support@preum.app.<br/><br/>Please be advised that through no fault of our own, some requests may limit platform functionality due to design and security.</p>
                </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyText",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
