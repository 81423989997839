<template>
  <b-row class="my-1 d-flex text-center">
    <b-col cols="12">
      <!-- <strong
        ><div
          :class="isError ? 'text-danger' : 'text-success'"
          v-show="message.length"
        >
          {{ message }}
        </div>
        </strong> -->
      <b-alert
        v-model="showAlert"
        :variant="isError ? 'danger' : 'success'"
        dismissible
      >
        {{ message }}
      </b-alert>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ShowMessage",
  props: {},
  data() {
    return {
      message: "",
      isError: false,
      showAlert: false,
    };
  },
  methods: {
    setMessage(text, isError = false, seconds = 20) {
      this.isError = isError;
      // console.log("setMessage called");
      if (text) {
        setTimeout(this.removeMessage, seconds * 1000);
        // console.log("setting message");
        this.showAlert = true;
        this.message = text;
      } else {
        // console.log("ShowMessage FAILED");
      }
    },
    removeMessage() {
      this.message = "";
      this.isError = false;
      this.showAlert = false;
    },
  },
  watch: {},
};
</script>
